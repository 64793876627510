<style lang="css">
* {
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
}

body {
  background-color: #ffffff !important;
  padding: 0Px 3Px;
}

.content {
  /*width: 4.7rem;*/
  margin: 0px auto;
  background-color: #ffffff;
}

.view_mod {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

}

.title_mod {
  margin: 0.1rem 0;
}

.title_mod > p {
  font-size: 19Px;
  font-weight: 400;
  color: #000000;
  line-height: 27Px;
  user-select: text;
}

.backImg_mod {
  width: 100%;
  height: 150Px;
  /*height: 1.5rem;*/
  margin: 0.25rem 0;
  position: relative;
  padding: 0 8Px;
}


.backgroud_img_mod {
  width: 100%;
  height: 90%;
  background: url(../../../assets/images/card_01.65ffc6b6.png) no-repeat;
  background-size: 100% 100%;
  border-top-left-radius: 18px;
  border-top-right-radius: 18px;
}

.head_portrait {
  position: absolute;
  width: 50Px;
  height: 50Px;
  bottom: -6Px;
  left: 20Px;
  border-radius: 65Px;
  border: 5Px solid #ffffff;
}

.head_portrait > img {
  width: 100%;
  height: 100%;
}

.bookinfo_mod {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 9Px;
}

.bookinfo_mod > div > p {
  line-height: 30Px;
}

.merchant_name {
  text-align: left;
}

.money_mod {
  text-align: right;
}

.merchant_name > .merchant_title_name {
  font-size: 20Px;
  font-width: 400;
}

.merchant_name > p:nth-child(2) {
  font-size: 16Px;
}

.userName_By > span {
  font-size: 16Px;
}

.money_mod > .money_mod {
  font-size: 21Px;
  font-width: 400;
  color: #4f7be1;
}

.bookinfo_mod > div {
  display: inline-block;
  line-height: 18Px;
}

.merchant_name > span {
  flex-wrap: nowrap;
}

.by_color {
  color: #8f92a1;
}

.bankinfo_mod {
  width: 100%;
  padding: 10px 20px;
  line-height: 61Px;
}

.bankinfo_mod > div {
  width: 100%;
  display: inline-block;
  line-height: 25Px;
}

.bankinfo_mod > div > p {
  text-align: left;
  line-height: 23Px;
  font-size: 20Px;
}

.bankinfo_mod > div > p:nth-child(2) {
  color: #000000;
  white-space: nowrap;
}

.bankinfo_mod > div > p:first-child {
  color: #8f92a1;
}

.copy_mod {
  margin-left: 1px;
  vertical-align: middle;
  display: inline-block;
  width: 1em;
  height: 1em;
  background: url("../../../assets/images/copy.png") no-repeat;
  background-size: 100%;
}

.piocead {
  width: 100%;
  text-align: right;
}

.piocead_but {
  display: inline-block;
  margin-right: 8px;
  text-align: center;
  font-size: 18Px;
  width: 120Px;
  height: 40Px;
  background-color: #15b1a7;
  color: #ffffff;
  font-weight: 400;
  line-height: 40Px;
  text-decoration: none;
  border-radius: 10Px;
  border: 0px;
}

@media screen and (max-width: 1000px) {
  .content {
    width: 100% !important;
  }
}

.el-message__content {
  padding: 0;
  font-size: 19Px !important;
}
</style>

<template>
  <div class="content">
    <div class="view_mod">
      <div class="title_mod">
        <p>Make a bank transfer to the account number<br> below to complete your transaction</p>
      </div>
      <div class="backImg_mod">
        <div class="backgroud_img_mod"></div>
        <div class="head_portrait">
          <img src="../../../assets/images/202204251214418622.png"/>
        </div>
      </div>
      <div class="bookinfo_mod">
        <div class="merchant_name">
          <p class="merchant_title_name">Merchant Name</p>
          <p class="title_name">Merchant</p>
        </div>
        <div class="money_mod">
          <p class="money_mod">NGN ₦ {{queryObject.amount}}</p>
          <p class="userName_By"><span class="by_color">By -</span><span>汉</span><span>尼拔</span>
          </p>
        </div>
      </div>
      <div class="bankinfo_mod">
        <div class="amount_mod">
          <p>AMOUNT</p>
          <p>NGN ₦ {{queryObject.amount}}</p>
        </div>
        <div class="account_number">
          <p>ACCOUNT NUMBER</p>
          <p>{{queryObject.accountNumber}}<a href="javascript:;" @click="copyFn(queryObject.accountNumber)" class="copy_mod"></a></p>
        </div>
        <div class="bank_name">
          <p>BANK NAME</p>
          <p>Coronation Merchant Bank</p>
        </div>
        <div class="beneficiary_name">
          <p>BENEFICIARY NAME</p>
          <p>{{queryObject.accountName}}<a href="javascript:;" @click="copyFn(queryObject.accountName)" class="copy_mod"></a></p>
        </div>
      </div>
      <div class="piocead">
        <a href="javascript:;" class="piocead_but"
           v-loading.fullscreen.lock="fullscreenLoading" @click="sumbit">Piocead</a>
      </div>
    </div>
  </div>
  <!--    <p>支付失败，请重新扫码进入！</p>-->
</template>
<script>
// 分发器页面，  用户扫码后进入的第一个页面：
// 判断是否存在channelUserId, 如果存在则直接跳到 对应的支付页面，如果不存在则重定向到对应的页面。
import $ from "jquery";
import {queryH5Order} from '@/api/api'

export default {
  data() {
    return {
      fullscreenLoading: false,
      // 订单号
      orderId: '',
      // 查询的数据
      queryObject: [],
      msg: "请通过正确的支付渠道进入！！",
    }
  },
  components: {},
  mounted() {
    this.msg = this.$route.params.errInfo || '请通过正确的支付渠道进入！'
    // 从数据库中获取 这个商户开通了哪些h5 支付插件
    const _this = this
    // 获取到订单号
    _this.orderId = this.$route.params.id
    console.log(_this.orderId)
    queryH5Order(_this.orderId).then(res => {
      console.log(res)
      _this.queryObject = res
    })
  },
  methods: {
    sumbit() {
      this.fullscreenLoading = true;
      setTimeout(() => {
        this.fullscreenLoading = false;
      }, 5000);
    },
    copyFn(val) {
      // createElement() 方法通过指定名称创建一个元素
      var copyInput = document.createElement("input");
      //val是要复制的内容
      copyInput.setAttribute("value", val);
      document.body.appendChild(copyInput);
      copyInput.select();
      try {
        var copyed = document.execCommand("copy");
        if (copyed) {
          document.body.removeChild(copyInput);
          this.$message({
            showClose: true,
            message: '复制成功',
            type: 'success',
            duration: '1000'
          });
          // this.$alert("", "复制成功", "success");
          $(".swal2-confirm").addClass("okbut")
          console.log("复制成功");
        }
      } catch {
        console.log("复制失败，请检查浏览器兼容");
      }
    },
  }

}
</script>
